<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-button
      v-checkbtn="isShowBtn(AUTH_BTN.dict_list_add)"
      style="margin-bottom:20px;"
      type="primary"
      icon="el-icon-plus"
      size="small"
      @click="goAdd"
      >新增</el-button
    >
    <!-- <div class="btn-group">
      <div></div>
      <div>
        <el-button type="primary" icon="el-icon-plus" size="small" @click="goAdd">新增</el-button>
      </div>
    </div> -->
    <tp-table :tableData="list" :columns="columns" :totalNum="total" :current-page.sync="currentPage" :pageSize.sync="pageSize" />
  </div>
</template>

<script>
import { getDictListAPI, delDict } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'
const columns = [
  {
    label: '字典名称',
    minWidth: '240',
    prop: 'dictName',
    customRender(h, row) {
      return (
        <p class="one-line" title={row['dictName']}>
          {row['dictName']}
        </p>
      )
    }
  },
  {
    label: '字典code值',
    minWidth: '240',
    prop: 'dictCode',
    customRender(h, row) {
      return (
        <p class="one-line" title={row['dictCode']}>
          {row['dictCode']}
        </p>
      )
    }
  },
  {
    label: '备注',
    minWidth: '200',
    prop: 'remark',
    customRender(h, row) {
      return (
        <p class="one-line" title={row['remark']}>
          {row['remark']}
        </p>
      )
    }
  },
  {
    label: '操作',
    fixed: 'right',
    minWidth: '150',
    customRender(h, row) {
      return (
        <div>
          <el-link
            v-show={this.isShowBtn(this.AUTH_BTN.dict_list_edit)}
            type="primary"
            disabled={row['isDefault'] == 1}
            onClick={() => this.goEdit(row['id'])}
          >
            编辑
          </el-link>
          <el-link
            v-show={this.isShowBtn(this.AUTH_BTN.dict_list_add_value)}
            style="margin:0 10px;"
            type="warning"
            onClick={() => this.goAddDictValue(row['dictCode'], row['dictName'])}
          >
            新增字典值
          </el-link>
          <el-link
            v-show={this.isShowBtn(this.AUTH_BTN.dict_list_del)}
            type="danger"
            disabled={row['isDefault'] == 1}
            onClick={() => this.goDel(row['id'])}
          >
            删除
          </el-link>
        </div>
      )
    }
  }
]
export default {
  mixins: [authBtnMixin],
  data() {
    return {
      columns,
      list: [],
      total: 0,
      currentPage: 1,
      pageSize: 10
    }
  },
  mounted() {
    this.getList()
  },
  provide() {
    return {
      context: this
    }
  },
  watch: {
    currentPage() {
      this.getList()
    },
    pageSize() {
      this.getList()
    }
  },
  methods: {
    async getList() {
      let params = {
        page: this.currentPage,
        pageSize: this.pageSize
      }
      const res = await getDictListAPI(params)
      this.list = res.list || []
      this.total = res.total || 0
    },

    goAdd() {
      this.$router.push({ name: 'dictManageAddOrEdit' })
    },
    goEdit(id) {
      this.$router.push({ name: 'dictManageAddOrEdit', params: { id: id || undefined } })
    },
    goAddDictValue(dictCode, dictName) {
      this.$router.push({ name: 'DictValueManage', query: { dictCode: dictCode || undefined, dictName: dictName || undefined } })
    },
    goDel(id) {
      this.$confirm('此操作将删除该字典, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delDict(id).then(() => {
            this.getList()
            this.$message.success('删除成功')
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  min-width: 800px;
  /deep/.one-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }
  .el-table__body-wrapper {
    width: 100%;
  }
  .el-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /deep/.el-form-item {
      margin-bottom: 0;
    }
  }
  .header {
    display: flex;
    justify-content: space-between;
  }
  .btn-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}
</style>
